<template>
  <div
    :class="[{ in: showing }, `alert-${type}`]"
    class="alert vue-alert pl-4 py-3 flex items-center justify-between"
    data-cy="flash-message">
    <div>
      {{ t(message, messageProps) }}
      <a
        v-if="messageProps && messageProps.link"
        class="underline"
        :target="messageProps.linkTarget"
        :href="messageProps.link">
        {{ t(messageProps.linkTitle) }}
      </a>
    </div>

    <div
      v-if="hideable"
      class="alert-close js-alert-close w-7 self-stretch flex items-center justify-center"
      @click="hideFlashMessages">
      <svg-icon name="close" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import SvgIcon from "./SvgIcon.vue";

export default {
  name: "FlashMessages",
  components: {
    SvgIcon
  },
  computed: {
    ...mapState({
      message: state => state.flashMessages.message,
      type: state => state.flashMessages.type,
      messageProps: state => state.flashMessages.messageProps,
      showing: state => state.flashMessages.showing,
      hideable: state => state.flashMessages.hideable
    })
  },
  methods: {
    hideFlashMessages() {
      this.$store.commit("flashMessages/setShowing", false);
    }
  }
};
</script>
